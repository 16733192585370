@charset "UTF-8";
/* General */
/* Menu Common */
/* Menu Light */
/* Menu Dark */
/* Topbar */
/* Footer */
.splash-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #0388E5;
  /* Old browsers */
  background: -moz-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0388E5), color-stop(100%, #07BDF4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* IE10+ */
  background: linear-gradient(to right, #0388E5 0%, #07BDF4 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$left, endColorstr=$right,GradientType=1 );
  /* IE6-9 */ }
  .splash-screen .splash-container {
    width: 40px;
    height: 40px;
    margin: 0px auto;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px; }
  .splash-screen .splash-double-bounce1, .splash-screen .splash-double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffffff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: splash-bounce 2.0s infinite ease-in-out;
    animation: splash-bounce 2.0s infinite ease-in-out; }
  .splash-screen .splash-double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s; }

@-webkit-keyframes splash-bounce {
  0%, 100% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); } }

@keyframes splash-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }

* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #333333;
  background-color: #edf0f5;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none; }

.layout-wrapper {
  padding: 0;
  min-height: 100vh; }

.mm_bg_focus {
  background-color: #ffea99 !important; }

.layout-topbar {
  position: fixed;
  height: 50px;
  padding: .7em 1.5em 0em 1.5em;
  color: #ffffff;
  z-index: 999;
  right: 0;
  background: #0388E5;
  /* Old browsers */
  background: -moz-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #0388E5), color-stop(100%, #07BDF4));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #0388E5 0%, #07BDF4 100%);
  /* IE10+ */
  background: linear-gradient(to right, #0388E5 0%, #07BDF4 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$left, endColorstr=$right,GradientType=1 );
  /* IE6-9 */
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s; }
  .layout-topbar:after {
    content: "";
    display: table;
    clear: both; }
  .layout-topbar .layout-topbar-icons {
    float: right;
    display: block;
    -moz-animation-duration: .5s;
    -webkit-animation-duration: .5s;
    animation-duration: .5s; }
    .layout-topbar .layout-topbar-icons .user-info {
      float: right;
      margin: 0px 10px;
      position: relative;
      top: -3px;
    }
    .layout-topbar .layout-topbar-icons button {
      float: right;
      position: relative;
      color: #ffffff;
      display: inline-block;
      text-decoration: none;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s;
      overflow: visible; }
      .layout-topbar .layout-topbar-icons button:hover {
        color: #c3e8fb; }
      .layout-topbar .layout-topbar-icons button span.layout-topbar-icon {
        font-size: 2em; }
      .layout-topbar .layout-topbar-icons button span.layout-topbar-item-text {
        font-size: 20px;
        display: none; }
      .layout-topbar .layout-topbar-icons button span.layout-topbar-badge {
        position: absolute;
        font-size: 10px;
        right: -5px;
        top: -5px;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 16px;
        color: #ffffff;
        background-color: #ef6262;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%; }
    .layout-topbar .layout-topbar-icons .layout-topbar-search {
      padding: 0;
      position: relative;
      display: inline-block;
      top: -4px; }
      .layout-topbar .layout-topbar-icons .layout-topbar-search input {
        display: inline-block;
        border: 0 none;
        font-size: 14px;
        background: transparent;
        border-bottom: 2px solid #ffffff;
        outline: 0 none;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: #ffffff;
        width: 100px;
        padding: 1px 20px 1px 1px;
        margin: 0px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search input::-webkit-input-placeholder {
          color: #ffffff;
          opacity: .7;
          -moz-transition: opacity 0.2s;
          -o-transition: opacity 0.2s;
          -webkit-transition: opacity 0.2s;
          transition: opacity 0.2s; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search input:-moz-placeholder {
          color: #ffffff;
          opacity: .7;
          -moz-transition: opacity 0.2s;
          -o-transition: opacity 0.2s;
          -webkit-transition: opacity 0.2s;
          transition: opacity 0.2s; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search input::-moz-placeholder {
          color: #ffffff;
          opacity: .7;
          -moz-transition: opacity 0.2s;
          -o-transition: opacity 0.2s;
          -webkit-transition: opacity 0.2s;
          transition: opacity 0.2s; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search input:-ms-input-placeholder {
          color: #ffffff;
          opacity: .7;
          -moz-transition: opacity 0.2s;
          -o-transition: opacity 0.2s;
          -webkit-transition: opacity 0.2s;
          transition: opacity 0.2s; }
      .layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon {
        font-size: 18px;
        position: absolute;
        top: -1px;
        right: 0px; }
      .layout-topbar .layout-topbar-icons .layout-topbar-search:hover input {
        border-bottom-color: #c3e8fb; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-webkit-input-placeholder {
          opacity: 1; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-moz-placeholder {
          opacity: 1; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-moz-placeholder {
          opacity: 1; }
        .layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-ms-input-placeholder {
          opacity: 1; }
      .layout-topbar .layout-topbar-icons .layout-topbar-search:hover .layout-topbar-search-icon {
        color: #c3e8fb; }
  .layout-topbar .layout-menu-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
    .layout-topbar .layout-menu-button span {
      font-size: 2em; }
    .layout-topbar .layout-menu-button:hover {
      color: #c3e8fb; }
  .layout-topbar button {
    cursor: pointer; }

.layout-sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  .layout-sidebar .layout-logo {
    text-align: center;
    margin-top: 24px; }
  .layout-sidebar .menuitem-badge {
    display: inline-block;
    margin-left: 4px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    background-color: #007be5;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%; }

.layout-profile {
  text-align: center;
  padding: 20px 0; }
  .layout-profile img {
    width: 56px;
    margin: 10px; }
  .layout-profile .layout-profile-link {
    cursor: pointer;
    display: inline-block;
    margin-bottom: .75em;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
    .layout-profile .layout-profile-link i {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle; }
  .layout-profile ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    -ms-transition-property: max-height;
    -o-transition-property: max-height;
    transition-property: max-height;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }
    .layout-profile ul.layout-profile-expanded {
      max-height: 500px; }
    .layout-profile ul li button {
      width: 100%;
      padding: 1em;
      border: 0 none;
      border-radius: 0;
      cursor: pointer;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      .layout-profile ul li button:hover {
        color: #0388e5; }
      .layout-profile ul li button span {
        margin-left: .25em;
        vertical-align: middle; }
      .layout-profile ul li button i {
        vertical-align: middle; }

.layout-sidebar-light .layout-profile .layout-profile-link {
  color: #232428; }
  .layout-sidebar-light .layout-profile .layout-profile-link:hover {
    color: #0388e5; }

.layout-sidebar-light .layout-profile ul {
  background-color: #ffffff; }
  .layout-sidebar-light .layout-profile ul li button {
    color: #232428; }
    .layout-sidebar-light .layout-profile ul li button:hover {
      color: #0388e5; }

.layout-sidebar-dark .layout-profile .layout-profile-link {
  color: #ffffff; }
  .layout-sidebar-dark .layout-profile .layout-profile-link:hover {
    color: #0388e5; }

.layout-sidebar-dark .layout-profile ul {
  background-color: #2e3035; }
  .layout-sidebar-dark .layout-profile ul li button {
    color: #ffffff; }
    .layout-sidebar-dark .layout-profile ul li button:hover {
      color: #0388e5; }

.layout-menu-container {
  padding-bottom: 120px; }

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .layout-menu li a {
    cursor: pointer;
    position: relative;
    text-decoration: none;
    display: block;
    -moz-transition: color 0.2s;
    -o-transition: color 0.2s;
    -webkit-transition: color 0.2s;
    transition: color 0.2s; }
    .layout-menu li a i {
      font-size: 18px;
      vertical-align: middle; }
    .layout-menu li a span {
      margin-left: .25em;
      vertical-align: middle;
      line-height: 18px;
      display: inline-block; }
    .layout-menu li a .menuitem-toggle-icon {
      position: absolute;
      top: 50%;
      right: 1em;
      margin-top: -9px; }
    .layout-menu li a.router-link-active {
      font-weight: 700; }
  .layout-menu li.active-menuitem > a .menuitem-toggle-icon:before {
    content: ""; }
  .layout-menu li.active-menuitem > ul {
    max-height: 500px; }
  .layout-menu > li > a {
    padding: 1em; }
    .layout-menu > li > a span {
      font-size: 14px; }
  .layout-menu > li:last-child > a {
    border-bottom: 1px solid rgba(207, 211, 224, 0.6); }
  .layout-menu > li ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 1.5em;
    max-height: 0;
    overflow: hidden;
    -webkit-transition-property: max-height;
    -moz-transition-property: max-height;
    -ms-transition-property: max-height;
    -o-transition-property: max-height;
    transition-property: max-height;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); }
    .layout-menu > li ul li a {
      cursor: pointer;
      padding: .75em 1em;
      border-top: 0 none; }
      .layout-menu > li ul li a span {
        font-size: 13px; }
    .layout-menu > li ul li:last-child {
      padding-bottom: 1em; }

.layout-sidebar-light {
  background: #f3f4f9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f3f4f9), color-stop(100%, #d7dbe8));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f3f4f9 0%, #d7dbe8 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */ }
  .layout-sidebar-light .layout-menu > li > a {
    border-top: 1px solid rgba(207, 211, 224, 0.6); }
  .layout-sidebar-light .layout-menu > li:last-child > a {
    border-bottom: 1px solid rgba(207, 211, 224, 0.6); }
  .layout-sidebar-light .layout-menu > li ul {
    background-color: #ffffff; }
  .layout-sidebar-light .layout-menu li a {
    color: #232428; }
    .layout-sidebar-light .layout-menu li a.router-link-active {
      color: #1fa1fc; }
    .layout-sidebar-light .layout-menu li a:hover {
      color: #0388e5; }
  .layout-sidebar-light .layout-menu li.active-menuitem > a {
    background-color: #ffffff;
    color: #0388e5; }

.layout-sidebar-dark {
  background: #4d505b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4d505b), color-stop(100%, #3b3e47));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #4d505b 0%, #3b3e47 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
  /* IE6-9 */ }
  .layout-sidebar-dark .layout-menu > li > a {
    border-top: 1px solid rgba(52, 56, 65, 0.6); }
  .layout-sidebar-dark .layout-menu > li:last-child > a {
    border-bottom: 1px solid rgba(52, 56, 65, 0.6); }
  .layout-sidebar-dark .layout-menu > li ul {
    background-color: #2e3035; }
  .layout-sidebar-dark .layout-menu li a {
    color: #ffffff; }
    .layout-sidebar-dark .layout-menu li a.router-link-active {
      color: #1fa1fc; }
    .layout-sidebar-dark .layout-menu li a:hover {
      color: #0388e5; }
  .layout-sidebar-dark .layout-menu li.active-menuitem > a {
    background-color: #2e3035;
    color: #0388e5; }

.layout-main {
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
  padding: 66px 16px 16px 16px; }

.mr-10 {
  margin-right: 10px; }

.headpageinfo ul {
  list-style: none;
  padding: 0; }

.layout-footer {
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
  background-color: #ffffff;
  padding: 1em 2em; }
  .layout-footer img {
    vertical-align: middle; }
  .layout-footer .footer-text {
    vertical-align: middle; }

@media (min-width: 1025px) {
  .layout-wrapper.layout-overlay .layout-sidebar {
    left: -250px; }
  .layout-wrapper.layout-overlay .layout-topbar {
    left: 0; }
  .layout-wrapper.layout-overlay .layout-main, .layout-wrapper.layout-overlay .layout-footer {
    margin-left: 0; }
  .layout-wrapper.layout-overlay.layout-overlay-sidebar-active .layout-sidebar {
    left: 0; }
  .layout-wrapper.layout-overlay.layout-overlay-sidebar-active .layout-topbar {
    left: 250px; }
  .layout-wrapper.layout-static .layout-sidebar {
    left: 0; }
  .layout-wrapper.layout-static .layout-topbar {
    left: 250px; }
  .layout-wrapper.layout-static .layout-main, .layout-wrapper.layout-static .layout-footer {
    margin-left: 250px; }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-sidebar {
    left: -250px; }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-topbar {
    left: 0; }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-main, .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-footer {
    margin-left: 0; } }

@media (max-width: 1024px) {
  .layout-wrapper .layout-topbar {
    left: 0; }
  .layout-wrapper .layout-main, .layout-wrapper .layout-footer {
    margin-left: 0; }
  .layout-wrapper .layout-sidebar {
    left: -250px;
    margin-top: 50px; }
  .layout-wrapper .layout-mask {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50px;
    left: 0;
    z-index: 998;
    background-color: #424242;
    opacity: 0.7;
    filter: alpha(opacity=70); }
  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar {
    left: -0; }
  .layout-wrapper.layout-mobile-sidebar-active .layout-mask {
    display: block; }
  .body-overflow-hidden {
    overflow: hidden; } }

/* Typography */
h1 {
  font-weight: normal;
  margin: 0;
  font-size: 24px; }

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0; }

.card {
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 16px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  .card.card-w-title {
    padding-bottom: 2em; }
  .card h1 {
    margin: 1em 0 .5em 0;
    border-bottom: 1px solid #d5d5d5;
    padding: .1em;
    font-size: 24px; }
    .card h1:first-child {
      margin: 0 0 .5em 0; }

.p-g {
  -ms-flex-wrap: wrap; }

/* Dashboard */
.dashboard .widget {
  margin-bottom: 20px;
  padding: 0; }
  .dashboard .widget .row-table {
    min-height: 118px;
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    margin: 0; }
    .dashboard .widget .row-table .bg-info {
      background-color: #00afd1;
      color: #ffffff; }
    .dashboard .widget .row-table .bg-green {
      background-color: #01b6ad;
      color: #ffffff; }
    .dashboard .widget .row-table .bg-danger {
      background-color: #f6504d;
      color: #ffffff; }
    .dashboard .widget .row-table .icon-size {
      font-size: 1.5em; }
    .dashboard .widget .row-table > [class*="col-"] {
      display: table-cell;
      float: none;
      table-layout: fixed;
      vertical-align: middle; }
  .dashboard .widget .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }
  .dashboard .widget .row-flush .card-body {
    font-size: .8em; }

.dashboard .summary {
  position: relative; }
  .dashboard .summary .title {
    font-size: 20px; }
  .dashboard .summary .detail {
    color: #707070;
    display: block;
    margin-top: 10px; }
  .dashboard .summary .count {
    color: #ffffff;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    padding: 7px 14px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    .dashboard .summary .count.visitors {
      background-color: #20d077; }
    .dashboard .summary .count.purchases {
      background-color: #f9c851; }
    .dashboard .summary .count.revenue {
      background-color: #007be5; }

.dashboard .highlight-box {
  height: 100px; }
  .dashboard .highlight-box:after {
    content: "";
    display: table;
    clear: both; }
  .dashboard .highlight-box .initials {
    height: 100%;
    float: left;
    width: 50%;
    text-align: center;
    padding: 1em; }
    .dashboard .highlight-box .initials > span {
      font-size: 48px; }
  .dashboard .highlight-box .highlight-details {
    height: 100%;
    background-color: #ffffff;
    float: left;
    width: 50%;
    padding: 1em; }
    .dashboard .highlight-box .highlight-details i {
      font-size: 24px;
      vertical-align: middle;
      margin-right: .25em; }
    .dashboard .highlight-box .highlight-details .count {
      color: #707070;
      font-size: 36px;
      display: block; }

.dashboard .task-list {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .dashboard .task-list li {
    padding: .5em .25em;
    border-bottom: 1px solid #e3e3e3; }
    .dashboard .task-list li:after {
      content: "";
      display: table;
      clear: both; }
  .dashboard .task-list .p-checkbox {
    vertical-align: middle;
    margin-right: .5em; }
  .dashboard .task-list .task-name {
    vertical-align: middle; }
  .dashboard .task-list i {
    float: right;
    font-size: 24px;
    color: #707070; }
  .dashboard .task-list .p-panel-content {
    min-height: 256px; }

.dashboard .contact-form .p-panel-content {
  min-height: 256px; }

.dashboard .contacts ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .dashboard .contacts ul li {
    border-bottom: 1px solid #e3e3e3; }
    .dashboard .contacts ul li button {
      padding: 9px;
      width: 100%;
      box-sizing: border-box;
      text-decoration: none;
      position: relative;
      display: block;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -moz-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }
      .dashboard .contacts ul li button .name {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 18px; }
      .dashboard .contacts ul li button .email {
        position: absolute;
        right: 10px;
        top: 30px;
        font-size: 14px;
        color: #707070; }
      .dashboard .contacts ul li button:hover {
        cursor: pointer;
        background-color: #eeeeee; }
    .dashboard .contacts ul li:last-child {
      border: 0; }

.dashboard .contacts .p-panel-content {
  min-height: 256px; }

.dashboard .activity-list {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .dashboard .activity-list li {
    border-bottom: 1px solid #e3e3e3;
    padding: 16px 8px; }
    .dashboard .activity-list li .count {
      font-size: 24px;
      color: #ffffff;
      background-color: #007be5;
      font-weight: 700;
      display: inline-block;
      padding: .25em .5em;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    .dashboard .activity-list li:first-child {
      border-top: 1px solid #e3e3e3; }
    .dashboard .activity-list li:last-child {
      border: 0; }
    .dashboard .activity-list li .p-g-6:first-child {
      font-size: 18px;
      padding-left: 0; }
    .dashboard .activity-list li .p-g-6:last-child {
      text-align: right;
      color: #707070; }

.devices-list .device-img {
  margin-top: 10px;
  margin-bottom: 10px; }

.devices-list .p-dataview-content {
  border: 0 !important; }

.devices-list .p-grid.child {
  border: 1px solid gray;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px; }

.app-img {
  margin: 10px; }
